import axios from 'axios'

class ArtistService {
    static updateArtist(data, onSuccess, onError) {
        console.log("data: ", data)
        axios.put(`/node/server.js/api/artists/update`, data)
            .then(response => onSuccess(response.data))
            .catch(error => onError(error));
    }

    static updatedRelatedArtists(data, onSuccess, onError) {
        console.log("data: ", data)
        axios.put(`/node/server.js/api/artists/related`, data)
            .then(response => onSuccess(response.data))
            .catch(error => onError(error));
    }

    static createArtist(data, onSuccess, onError) {
        console.log("data: ", data)
        axios.post(`/node/server.js/api/artists`, data)
            .then(response => onSuccess(response.data))
            .catch(error => onError(error));
    }

    static selectAll(onSuccess, onError) {
        axios.get('/node/server.js/api/artists')
            .then(response => onSuccess(response.data))
            .catch(error => onError(error));
    }
    static selectAllBasicDetails(onSuccess, onError) {
        axios.get('/node/server.js/api/artists/details')
            .then(response => onSuccess(response.data))
            .catch(error => onError(error));
    }
    static selectById(data, onSuccess, onError) {
        axios.get(`/node/server.js/api/artists/${data}`)
            .then(response => onSuccess(response.data))
            .catch(error => onError(error));
    }

    static getArtistAndCatalog(ArtistId, onSuccess, onError) {
        let one = `/node/server.js/api/artists/${ArtistId}`
        let two = '/node/server.js/api/releases'
        const requestOne = axios.get(one);
        const requestTwo = axios.get(two)
        axios.all([requestOne, requestTwo])
            .then(axios.spread((...responses) => {
                const artistInfo = responses[0].data;
                const catalog = responses[1].data
                onSuccess({ artistInfo, catalog })
            }
            ))
            .catch(error => onError(error))
    }

}
export default ArtistService