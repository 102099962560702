import axios from 'axios'

class ReleasesService {
    static create(data, onSuccess, onError) {

        axios.post('/node/server.js/api/releases', data)
            .then(response => onSuccess(response.data))
            .catch(error => onError(error));
    }
    static selectAll(onSuccess, onError) {
        axios.get('/node/server.js/api/releases')
            .then(response => onSuccess(response.data))
            .catch(error => onError(error));
    }
    static selectAllBasicDetails(onSuccess, onError) {
        axios.get('/node/server.js/api/releases/details')
            .then(response => onSuccess(response.data))
            .catch(error => onError(error));
    }

    static selectAllSpotlight(onSuccess, onError) {
        axios.get('/node/server.js/api/releases/spotlight')
            .then(response => onSuccess(response.data))
            .catch(error => onError(error));
    }

    static selectById(data, onSuccess, onError) {
        console.log("frontend service data passed:", data)
        axios.get(`/node/server.js/api/releases/${data}`)
            .then(response => onSuccess(response.data))
            .catch(error => onError(error));
    }
    static updateAlbum(data, onSuccess, onError) {
        console.log("frontend service data passed:", data)
        axios.put(`/node/server.js/api/releases/update`, data)
            .then(response => onSuccess(response.data))
            .catch(error => onError(error));
    }

    static updateCurrent(data, onSuccess, onError) {
        console.log("frontend service data passed:", data)
        axios.put(`/node/server.js/api/releases/current`, data)
            .then(response => onSuccess(response.data))
            .catch(error => onError(error));
    }
}
export default ReleasesService