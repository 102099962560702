import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <div className="fake-mackie-two mt-4" align="left" style={{cursor: 'pointer'}} onClick={() => logout({ returnTo: window.location.origin })}>
      Logout
    </div>
  );
};

export default LogoutButton;