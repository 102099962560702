import React from 'react'
import ReleasesService from '../services/ReleasesService'
import CurrentDragAndDrop from './CurrentDragAndDrop'
import { applyDrag } from './utils'



class CurrentAndNotable extends React.Component {

    state = {
        current: [],
        albumDropdown: [],
        albumData: [],
        currentDisplay: []
    }

componentDidMount(props) {
        ReleasesService.selectAllBasicDetails(this.getReleasesSuccess, this.getReleasesError)
    }
    
getReleasesSuccess = (response) => {
        let albumData = response

        albumData.sort((itema, itemb) => {
            const nameA = itema.releaseDate
            const nameB = itemb.releaseDate
            return nameB.localeCompare(nameA)
        })


        const mapAlbums = (album) => { return <option value={album.releases_id} key={album.releases_id}>{album.artists.artist} - {album.title}</option> }
        const albumDropdown = albumData.map(mapAlbums)

        let current = []
        for (let i = 0; i < albumData.length; i++) {
                if (albumData[i].feat === "Y") { current.push(albumData[i]) }

    current.sort((a, b) => (a.featOrder > b.featOrder) ? 1 : -1)

        this.setState({
            albumDropdown: albumDropdown,
            current: current,
            albumData: albumData,
            currentDisplay:  <CurrentDragAndDrop albums={current} delete={this.handleDelete} drop={this.handleDrop}/>
        })

    }}



getReleasesError(err) {
    console.log('Get all releases failed: ', err)}


handleChange = (e) =>{
    let id = Number(e.target.value)
    let currentArray = this.state.current
    let albumData = this.state.albumData
    const newAlbum = albumData.findIndex(item => item.releases_id === id)
    albumData[newAlbum].feat = "Y"
    albumData[newAlbum].featOrder = currentArray.length + 1

    console.log(albumData, id, newAlbum)
    this.getReleasesSuccess(albumData)
    let data = {
        releases_id: id, 
        feat: 'Y', 
        featOrder: currentArray.length + 1}
    ReleasesService.updateCurrent(data, this.UpdateCurrentSuccess, this.UpdateCurrentError)
}

handleDelete = (e) =>{
    let id = Number(e.target.value)
    let albumData = this.state.albumData
    const newAlbum = albumData.findIndex(item => item.releases_id === id)
    albumData[newAlbum].feat = "N"
    albumData[newAlbum].featOrder = 0
    this.getReleasesSuccess(albumData)
    let data = {
        releases_id: id, 
        feat: 'N', 
        featOrder: 0}
    ReleasesService.updateCurrent(data, this.UpdateCurrentSuccess, this.UpdateCurrentError)



}

UpdateCurrentSuccess = (resp) => {console.log("updated!", resp)}
UpdateCurrentError = (err) => {console.log(err)}



handleDrop = (e) => {
  let current = this.state.current
  let newOrder = applyDrag(current, e)
  console.log(current)
  console.log(newOrder)
  

  const updateOrder = (item) => {
    let data = 
    {releases_id: item.releases_id,
    featOrder: newOrder.indexOf(item)}
    ReleasesService.updateCurrent(data, this.UpdateCurrentSuccess, this.UpdateCurrentError)
     }

    newOrder.map(updateOrder)
  
    this.setState((prevState) => {
    return {
        ...prevState,
        current: newOrder,
        currentDisplay: <CurrentDragAndDrop albums={newOrder} delete={this.handleDelete} drop={this.handleDrop}/>,
    }
}

)


}



render() {
    

    return (
    <React.Fragment>
      <div className="row-cols-xl-5">{this.state.currentDisplay}</div>
       <div className="fake-mackie-two">add</div>
       <div className="mt-4 ml-2" align="left"><select id="artistToAdd" onChange={this.handleChange}><option>add album... </option>{this.state.albumDropdown}</select></div>

       </React.Fragment>
       
       
       
       
       
       
       
       
       )
}

}

export default CurrentAndNotable
