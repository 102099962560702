import React from 'react'; import "../ipecac.css"
import {NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faTwitter, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'
import "../ipecac.css"


class NavBar extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className="nav-box">
                    <NavLink href="/">
                        <img src="https://assets.ipecac.com/images/logo.png" alt="logo" height="100"></img>
                    </NavLink>

                    <div className="d-inline-flex">
                        <NavLink className="black-nav" href="/artists/">ARTISTS</NavLink>
                        <NavLink className="black-nav" href="/releases/">RELEASES</NavLink>
                        <NavLink className="black-nav" href="/tours/">TOURS</NavLink>

                        <UncontrolledDropdown >
                            <DropdownToggle className="black-nav" nav caret>Shop</DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem href="https://ipecacrecordings.bandcamp.com/">Bandcamp
                        </DropdownItem>
                                <DropdownItem href="https://ipecac-music.myshopify.com/">
                                US Music Store (Blixt)
                  </DropdownItem>
                                <DropdownItem href="https://blixtmerchandise.shop/ipecac">
                                    Merch Store (Blixt)
                  </DropdownItem>
                                <DropdownItem href="https://ipecac.tmstor.es/">
                                UK/EU Music Store (Townsend)
                  </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>

                        <UncontrolledDropdown>
                            <DropdownToggle className="black-nav" nav caret>
                                INFO
                </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem href="/newsletter/">
                                    Join Our Newsletter
                  </DropdownItem>
                                <DropdownItem href="/about/">
                                    About Ipecac
                  </DropdownItem>
                                <DropdownItem><a href="mailto:info@ipecac.com"><FontAwesomeIcon icon={faEnvelope} /> Contact
                    </a></DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        
                    </div>

                    <div className="mt-1 mx-5 d-inline-flex">
                                <a href="https://www.facebook.com/ipecac"><FontAwesomeIcon className="mx-1 black-nav" target="_blank" rel="noopener noreferrer" icon={faFacebook} /></a>
                                <a href="https://twitter.com/IpecacRec"><FontAwesomeIcon className="mx-1 black-nav" target="_blank" rel="noopener noreferrer" icon={faTwitter} /></a>
                                <a href="https://www.instagram.com/ipecacrecordings/"><FontAwesomeIcon className="mx-1 black-nav" target="_blank" rel="noopener noreferrer" icon={faInstagram} /></a>
                                <a href="https://www.youtube.com/ipecacrecordings"><FontAwesomeIcon className="mx-1 black-nav" target="_blank" rel="noopener noreferrer" icon={faYoutube} /></a>
                            </div>
                </div >
            </React.Fragment>
        )
    }
}

export default NavBar