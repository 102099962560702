import React from 'react'
import "../ipecac.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTicketAlt } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'reactstrap'
import moment from 'moment'

class TourDates extends React.Component {
    state = {
        tourDates: [],
        length: []
    }


    componentDidMount() {
        let tourDates = this.props.tourDates

        tourDates.sort(function compare(a, b) {
            var dateA = new Date(a.date);
            var dateB = new Date(b.date);
            return dateA - dateB;
          });


        this.renderTourDates(tourDates)
    }

    handleEdit = (show) => {
        this.props.handleEdit(show)
    }

    handleDelete = (show) => {
        this.props.handleDelete(show)
    }



    renderTourDates = (tourDates) => {

        const mappedTourDates = tourDates.map(this.mapTourDates)

        this.setState((prevState) => {
            return {
                ...prevState,
                tourDates: mappedTourDates,
            };
        });
       

    }

    mapTourDates = (show) => {
            let dateDisplay = moment.utc(show.date).format("MMM. D, YYYY")

        
        return <div key={show.tourIndex}>
        
        <div className="row my-3" align="left">
        <Button className="mr-2" onClick={()=>{this.handleEdit(show)}} color="primary">Edit</Button>
        <Button className="mr-2" onClick={()=>{this.handleDelete(show.tourIndex)}} color="danger">Delete</Button>
            <div className="col-md-2">{dateDisplay}</div>
            <div className="col-md-3 name">{show.location}</div>
            <div className="col-md-3">{show.venue}</div>

            {show.tix ?
                <div className="col-md-1"><a href={show.tix}><FontAwesomeIcon icon={faTicketAlt} /></a></div>
                : null}
        </div>
        <div className="d-xs-block d-sm-block d-md-block d-lg-none">&nbsp;</div></div>}

    render() {

        return (
            <div>{this.state.tourDates}</div>)
    }
}
export default TourDates
