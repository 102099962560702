import React from 'react';
import { Route, Switch } from "react-router-dom";
import NavBar from "./homepage/NavBar"
import Home from "./homepage/Home"
import Artists from "./pages/Artists"
import Releases from "./pages/Releases"
import About from "./pages/About"
import Newsletter from './pages/Newsletter';
import TourPage from './tour/TourPage'
import AdminLanding from './admin/AdminLanding'
import { Auth0Provider } from '@auth0/auth0-react';
import ProtectedRoute from "./auth/protected-route"

function App() {
  return (
    <Auth0Provider
     domain="ipecac.us.auth0.com"
     clientId="tg6TkM4PxwpGCyYQlkTTLOWSogc7PZaB"
     redirectUri="https://www.ipecac.com/admin"
    >
        <NavBar />
        <Switch>
        <Route path="/" exact={true} component={Home} />
        <Route path="/artists/:slug" exact={true} component={Artists} />
        <Route path="/artists" exact={true} component={Artists} />
        <Route path="/releases" exact={true} component={Releases} />
        <Route path="/about" exact={true} component={About} />
        <Route path="/newsletter" exact={true} component={Newsletter} />
        <Route path="/tours" exact={true} component={TourPage} />
        <ProtectedRoute path="/admin" component={AdminLanding} />
        </Switch>
        <div className="footer mt-5 mb-2">© 1999-2021 Ipecac Recordings. All Rights Reserved. • Site by <a href="https://twitter.com/85etc_">85etc.</a>
      </div>
    </Auth0Provider>
  );
}

export default App