import React from 'react'
import { Button } from 'reactstrap'
import { Draggable, Container } from 'react-smooth-dnd'

function Current(props) {

    let albums = props.albums

    const mapAlbums = (album) => {
        let coverThumb = `http://assets.ipecac.com/images/releases/${album.cat}t.jpg`
    return <Draggable key={album.releases_id} className="draggable-item-horizontal">
        <div className="card border-0 my-3" >
            <img src={coverThumb} alt="album art" height="100%" width="180px" className="d-block mx-auto mx-sm-0 pointer" />

            <div className="d-block mx-auto mx-sm-0 name pointer">{album.artists.artist}</div>
            <div className="d-block mx-auto mx-sm-0 album pointer">{album.title}</div>
            <div><Button value={album.releases_id} onClick={props.delete} color="danger" size="sm">REMOVE</Button></div>
</div></Draggable>}


    const mappedAlbums = albums.map(mapAlbums)


    return (
        <div className="mx-3">
            <div><Container onDrop={props.drop} orientation="horizontal">{mappedAlbums}</Container></div></div>)
}


export default Current



