import React from 'react';
import { Formik } from 'formik';
import '../ipecac.css'
import moment from 'moment'


const nullChecker = (info) => { if (info) { return info } else { return "" } }


const TourDateEditForm = (props) => (

    
    <div align="left">
            {(props.isNew === true) ? <div><div className="black-nav-admin mt-5">OR</div><div className="fake-mackie-two">ADD SINGLE TOUR DATE</div></div> : <div className="fake-mackie-two">EDIT TOUR DATE</div>}


        <Formik
            initialValues={{
                tourIndex: props.show.tourIndex,
                artistId: props.show.artistId,
                date: moment.utc(props.show.date).format("YYYY-MM-DD"),
                location: nullChecker(props.show.location),
                venue: nullChecker(props.show.venue),
                tix: nullChecker(props.show.tix),
            }}

            validate={values => {
                const errors = {};
                if (!values.date) {
                    errors.date = 'Required'
                }

                if (!values.location) {
                    errors.location = 'Required'
                }

                if (!values.venue) {
                    errors.venue = 'Required'
                }
                return errors;
            }}

            onSubmit={(values, { setSubmitting }) => {
                console.log("WE ARE SUBMITTING THESE VALUES: ", values)
                setTimeout(() => {
                    setSubmitting(false);
                }, 400);
                props.onSubmit(values)
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="black-nav-admin mt-3">DATE</div>
                        <input
                            type="date"
                            name="date"
                            className="admin-input"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.date}
                        />
                        {errors.date ? <div style={{ color: "red" }}>{errors.date}</div> : null}



                        <div className="black-nav-admin mt-3">LOCATION</div>
                        <input
                            type="text"
                            className="admin-input"
                            name="location"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.location}
                        />

                   
            {errors.location ? <div style={{ color: "red" }}>{errors.location}</div> : null}


                        <div className="black-nav-admin mt-3">VENUE</div>
                        <input
                            type="text"
                            className="admin-input"
                            name="venue"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.venue}
                        />

                   
            {errors.venue ? <div style={{ color: "red" }}>{errors.venue}</div> : null}

                       

                   
            <div className="black-nav-admin mt-3">TICKET LINK</div>
                        <input
                            type="text"
                            className="admin-input"
                            name="tix"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.tix}
                        />

                                                            

                        <div className="mt-3">
                            <button type="submit" disabled={isSubmitting} className="btns">
                                Submit
           </button></div>
                    </form>
                )}
        </Formik>
    </div>
);

export default TourDateEditForm