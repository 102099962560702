import React from 'react';
import { Formik, Field } from 'formik';
import '../ipecac.css'


const nullChecker = (info) => { if (info) { return info } else { return "" } }

const ArtistEditForm = (props) => (

    


    <div align="left">
        {(props.isNew === true) ? <div className="fake-mackie-two">ADD ARTIST</div> : <div className="fake-mackie-two">EDIT ARTIST</div>}

        <Formik
            initialValues={{
                artist: nullChecker(props.artist),
                artistId: props.artistId,
                bandUrl: nullChecker(props.bandUrl),
                nowActive: props.nowActive,
                bio: nullChecker(props.bio),
                slug: nullChecker(props.slug)
            }}

            validate={values => {
                const errors = {};
                if (!values.artist) {
                    errors.artist = 'Required'
                }

                if (!values.slug) {
                    errors.slug = 'Required'
                }
                return errors;
            }}

            onSubmit={(values, { setSubmitting }) => {
                console.log("WE ARE SUBMITTING THESE VALUES: ", values)
                setTimeout(() => {
                    setSubmitting(false);
                }, 400);
                props.onSubmit(values)
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="black-nav-admin mt-3">Artist Name</div>
                        <input
                            type="text"
                            className="admin-input"
                            name="artist"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.artist}

                        />
                        {errors.artist ? <div style={{ color: "red" }}>{errors.artist}</div> : null}



                        <div className="black-nav-admin mt-3">URLs</div>
                        <div className="album">Please separate by commas</div>
                        <input
                            type="text"
                            className="admin-input"
                            name="bandUrl"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.bandUrl}
                        />

                    {props.isNew === false ? <div>
                        <div id="nowActive" className="black-nav-admin mt-3">Artist Page Status</div>
                        <div role="group" aria-labelledby="nowActive" value={values.nowActive}>
                            <label className="mr-2">
                                <Field type="radio" name="nowActive" value="Y" />Now Active</label>
                            <label className="ml-2">
                                <Field type="radio" name="nowActive" value="N" />Family</label>
                        </div></div>
                        : <div/>
                        
                        }



                        <div className="black-nav-admin mt-3">Bio</div>
                        <textarea
                            type="text"
                            name="bio"
                            rows="10"
                            className="admin-input"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.bio}
                        />

                   
                        <div className="black-nav-admin mt-3">Slug</div>
                        <div className="album">Lowercase, separated by dashes</div>
                        <span>https://ipecac.com/artists/</span><input
                            type="text"
                            className="admin-input-small"
                            name="slug"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.slug}
                        />
                        {errors.slug ? <div style={{ color: "red" }}>{errors.slug}</div> : null}
                       
                       

                        

                        <div className="mt-3">
                            <button type="submit" disabled={isSubmitting} className="btns">
                                Submit
           </button></div>
                    </form>
                )}
        </Formik>
    </div>
);

export default ArtistEditForm