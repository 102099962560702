import React from 'react'
import "../ipecac.css"

class Newsletter extends React.Component {

    render() {
        return (
                <div className="container mt-4">
                <div id="newsletter">
                <div className="ctct-inline-form" data-form-id="a998f8b9-e131-4d67-b353-87db38728dde"></div>
                </div></div>
        )
    }
}

export default Newsletter