import axios from 'axios'

class TourService {
    static selectAll(onSuccess, onError, spotlight) {
        axios.get('/node/server.js/api/tour')
            .then(response => onSuccess({response, spotlight}))
            .catch(error => onError(error));
    }

    static updateTourDate(data, onSuccess, onError) {
        console.log("frontend service data passed:", data)
        axios.put(`/node/server.js/api/tour/update`, data)
            .then(response => onSuccess(response.data))
            .catch(error => onError(error));
    }

    // 
    static deleteTourDate(dateIndex, onSuccess, onError) {
        console.log("frontend service data passed:", {params: {tourIndex: dateIndex }} )
        axios.delete(`/node/server.js/api/tour/`, {params: {tourIndex: dateIndex }} )
            .then(response => onSuccess(response.data))
            .catch(error => onError(error));
    }

    static addTourDate(data, onSuccess, onError) {
        console.log("data: ", data)
        axios.post(`/node/server.js/api/tour`, data)
            .then(response => onSuccess(response.data))
            .catch(error => onError(error))}






}

    

    export default TourService
