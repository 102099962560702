import React from 'react'
import Albums from '../albums/Albums'
import ReleasesService from '../services/ReleasesService'
import "../ipecac.css"

class Releases extends React.Component {

    state = {
        catalog: []
    }

    componentDidMount() {
        ReleasesService.selectAllBasicDetails(this.getReleasesSuccess, this.getReleasesError)
    }

    getReleasesSuccess = (response) => {
        response.sort((albuma, albumb) => (albuma.releaseDate < albumb.releaseDate) ? 1 : -1)
        this.setState({ catalog: response })
    }

    getReleasesError(err) {
        console.log('Get all releases failed: ', err)
    }

    render() {
        return (<React.Fragment>
            <div align="center">
            <div className="fake-mackie mx-5 mb-1 mt-3">releases</div>
            <div className="mx-5 col-10">
                <div><Albums albums={this.state.catalog} /></div></div></div></React.Fragment>)
    }
}

export default Releases