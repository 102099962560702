import React from 'react'
import TourService from '../services/TourService'
import AdminTourDateMapper from "./AdminTourDateMapper"
import TourDateEditForm from "./TourDateEditForm"

import { withRouter } from "react-router-dom";


class TourUpdate extends React.Component {

    state = {
        mappedTourdates: [],
        tourDatesUnfiltered: [],
        isEdit: false,
        tourDatetoEdit: [],
        filteredArtists: []
    }
componentDidMount() {
        TourService.selectAll(this.getTourDatesSuccess, this.getTourDatesError)
    }

getTourDatesSuccess = (responseObj) => {
let tourDates = responseObj.response.data
let filteredArtists = this.filterTourDates(tourDates)
this.renderTourDates (tourDates, filteredArtists)}





renderTourDates = (tourDates, filteredArtists) => {

            for (let j = 0; j < filteredArtists.length; j++) {
                filteredArtists[j].dates = [];
              for (let i = 0; i < tourDates.length; i++) {
                if (tourDates[i].artistId === filteredArtists[j].artistId) {
                    filteredArtists[j].dates.push(tourDates[i]);}}}

                   
   
    this.setState((prevState) => {
            return {
                ...prevState,
                tourDatesUnfiltered: tourDates,
                filteredArtists: filteredArtists,
                mappedTourdates: filteredArtists.map(this.mapArtists)
            };
        })
    
    }

mapArtists = (artist) => {
    if (artist.dates.length === 0){this.props.history.push("/tours")}
    return <div className="container my-4" key={`${artist.artistId}-${artist.dates.length}`}> <div className="fake-mackie mb-2">{artist.artists.artist}</div>
    <AdminTourDateMapper tourDates={artist.dates} handleEdit={this.handleEdit} handleDelete={this.handleDelete}/>
    </div>}

filterTourDates = (tourDates) => {
        var filteredArtists = tourDates.filter((v,i,a)=>a.findIndex(t=>(t.artists.artist === v.artists.artist))===i)
    
        filteredArtists.sort((itema, itemb) => {
            const nameA = itema.artists.artist.toLowerCase().replace(/^the\s+/, '');
            const nameB = itemb.artists.artist.toLowerCase().replace(/^the\s+/, '');
            return nameA.localeCompare(nameB)
        })
        
        return filteredArtists}
    
    getTourDatesError = (err) => console.log(err)

    handleEdit = (show) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                tourDatetoEdit: show,
                isEdit: true
            };
        })}

    tourDateUpdate = (data) => {
            TourService.updateTourDate(data, this.tourDateUpdateSuccess, this.tourDateUpdateError)
        }
    tourDateUpdateSuccess = (resp) => {
            console.log("tour date UPDATE SUCCESS! ", resp)
            this.props.history.push("/tours")
        }
    tourDateUpdateError = (err) => {
            console.log("THERE WAS AN ERROR IN YOUR UPDATE: ", err)
        }


    handleDelete = (show) => {

        let currentTourDates = this.state.tourDatesUnfiltered
        
        let filteredArtists = this.state.filteredArtists
        for (var i = 0; i < currentTourDates.length; i++) {
        if (currentTourDates[i].tourIndex === Number(show)) { currentTourDates.splice(i, 1) }
                             }
        this.renderTourDates(currentTourDates, filteredArtists)




    let data = show
        TourService.deleteTourDate(data, this.tourDateDeleteSuccess, this.tourDateDeleteError)
    }

        
        tourDateDeleteSuccess = (resp) => {
            console.log("tour date delete SUCCESS! ", resp)

          
               
            }
            tourDateDeleteError = (err) => {
                console.log("THERE WAS AN ERROR IN YOUR UPDATE: ", err)
            }


    render() {
        return (
        <React.Fragment>
            {(this.state.isEdit === false) ? 
            <div align="center" className="mx-5"> 
            <div className="mx-5 col-10">{this.state.mappedTourdates}</div></div>
            

            : <TourDateEditForm show={this.state.tourDatetoEdit} onSubmit={this.tourDateUpdate}/>}
        </React.Fragment>



        )
    }
}

export default withRouter(TourUpdate)










