import React from 'react'
import "../ipecac.css"

class About extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className="container mt-4" align="left">
                    <div className="fake-mackie">About Ipecac Recordings</div>
                    <p>Just what the world needs... another damn label! In this era of mind-numbing musical mediocrity, sagging sales, major label mega-mergers and indie label burn-out, why the hell would anyone do something crazy like start a label?! Like the medicine it's named after, Ipecac Recordings is here to purge you of the drek that's been rotting in your tummies.</p>

                    <img src="//assets.ipecac.com/images/werckman_and_patton_small.jpg" width="200" height="150" alt="Greg Werckman and Mike Patton" align="left" className="mr-3" /> <p>Ipecac Recordings is the brainchild of Greg Werckman (former label manager for the legendary punk label, Alternative Tentacles, employee for Mercury Records for a record 15 minutes, currently operating Pick-A-Winner Management) and Mike Patton (former frontman for Faith No More,  Mr. Bungle, currently of Fantômas, Tomahawk, Peeping Tom).</p>

                    <p>Werckman and Patton had been scheming for years (in between video games) about starting their very own label, waiting for just the right moment to unleash their master plan. Ipecac Recordings is not about to make music biz history and execute a revolution (although one is surely needed) or bore you by waxing poetic about their original musical philosophies. But if we have to adhere to a "mission statement," how about the notion of being an honest, artist friendly label run on a shoe string with no outrageous promotional or production costs. This label is a home to Mike Patton's eclectic musical collaborations as well as a place where bands we admire have the freedom to release music they might not be able to, or want to, release on other labels.</p>

                </div>
            </React.Fragment>
        )
    }
}

export default About