import React from 'react';
import TourArtist from '../artists/TourArtist';
import Albums from '../albums/Albums';
import ReleasesService from '../services/ReleasesService';
import TourService from '../services/TourService';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import '../ipecac.css';
import { withRouter } from 'react-router-dom';

const receiveIFrameHeight = (event) => {
  if (event.data.hasOwnProperty('frameHeight')) {
    document.getElementById(
      'promo-iframe'
    ).style.height = `${event.data.frameHeight}px`;
  }
};
class Home extends React.Component {
  state = {
    tourArtists: [],
    spotlight: [],
  };

  componentDidMount() {
    ReleasesService.selectAllSpotlight(
      this.getReleasesSuccess,
      this.getReleasesError
    );
    window.addEventListener('message', receiveIFrameHeight);
  }

  componentWillUnmount() {
    window.removeEventListener('message', receiveIFrameHeight);
  }

  getReleasesSuccess = (response) => {
    let spotlight = response;
    spotlight.sort((albuma, albumb) =>
      albuma.featOrder > albumb.featOrder ? 1 : -1
    );
    this.getTourArtists(spotlight);
  };

  getTourArtists = (spotlight) => {
    TourService.selectAll(
      this.getTourDatesSuccess,
      this.getTourDatesError,
      spotlight
    );
  };

  getTourDatesSuccess = (responseObj) => {
    let spotlight = responseObj.spotlight;
    let tourDates = responseObj.response.data;

    var filteredArtists = tourDates.filter(
      (v, i, a) =>
        a.findIndex((t) => t.artists.artist === v.artists.artist) === i
    );

    filteredArtists.sort((itema, itemb) => {
      const nameA = itema.artists.artist.toLowerCase().replace(/^the\s+/, '');
      const nameB = itemb.artists.artist.toLowerCase().replace(/^the\s+/, '');
      return nameA.localeCompare(nameB);
    });

    const mapArtists = (item) => (
      <TourArtist
        key={item.artistId}
        artist={item.artists.artist}
        artistId={item.artistId}
        onClick={this.onClick}
      />
    );

    const mappedArtists = filteredArtists.map(mapArtists);

    this.setState({ spotlight: spotlight, tourArtists: mappedArtists });
  };

  getTourDatesError = (response) => {
    console.log(response);
  };

  onClick = (props) => {
    this.props.history.push('/tours');
    window.scrollTo(0, 0);
  };

  getReleasesError(err) {
    console.log('Get all releases failed: ', err);
  }

  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12">
            <iframe
              id="promo-iframe"
              title="Ipecac Homepage Promo"
              src="//assets.ipecac.com/promo/promo.html"
              style={{ width: '100%', border: 'none', minHeight: '315px' }}
            ></iframe>
          </div>
          <div className="col-3 d-none d-sm-none d-md-block">
            <div className="mt-5 fake-mackie-two">
              NEWS{' '}
              <a
                href="https://twitter.com/IpecacRec"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  className="fake-mackie-three"
                  icon={faExternalLinkAlt}
                />
              </a>
            </div>
            <div className="ml-4 ">
              <TwitterTimelineEmbed
                sourceType="profile"
                screenName="IpecacRec"
                options={{ height: 800, id: 'profile:IpecacRec' }}
                //noHeader={true}
                noScrollbar={true}
              />
            </div>
          </div>
          <div className="col-12 col-md-9">
            <div className="mt-5 fake-mackie">CURRENT and NOTABLE</div>
            <div>
              <Albums albums={this.state.spotlight} />
            </div>
            <div className="fake-mackie-three">
              <a href="/releases">full catalog... </a>
            </div>
            <div className="mt-3 fake-mackie">ON TOUR</div>
            <div className="mx-3 mt-3">
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5">
                {this.state.tourArtists}
              </div>
            </div>
          </div>

          <div className="col-12 d-md-none">
            <div className="mt-5 fake-mackie-two">
              NEWS{' '}
              <a
                href="https://twitter.com/IpecacRec"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  className="fake-mackie-three"
                  icon={faExternalLinkAlt}
                />
              </a>
            </div>
            <TwitterTimelineEmbed
              sourceType="profile"
              screenName="IpecacRec"
              options={{ height: 400, id: 'profile:IpecacRec' }}
              //noHeader={true}
              noScrollbar={true}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Home);
