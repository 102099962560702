import React from 'react'
import ArtistUpdate from "./ArtistUpdate"
import AlbumUpdate from "./AlbumUpdate"
import TourUpdate from "./TourUpdate"
import TourAdd from "./TourAdd"
import RelatedArtists from "./RelatedArtists"
import CurrentAndNotable from "./CurrentAndNotable"
import LogoutButton from "./LogoutButton"



class AdminLanding extends React.Component {



    state = {
        link: 0,
    }


    handleClick = (id) => {
        switch (id) {
            case 1:
                return this.setState({ link: 1 })
            case 2:
                return this.setState({ link: 2 });
            case 3:
                return this.setState({ link: 3 })
            case 4:
                return this.setState({ link: 4 })
            case 5:
                return this.setState({ link: 5 })
            case 6:
                return this.setState({ link: 6 })
            case 7:
                return this.setState({ link: 7 })
            case 8:
                return this.setState({ link: 8 })
            default:
                return null;
        }

    }

    handleLogout = () => {
    this.props.history.push("/")}


    render() {
        return (
            <React.Fragment >
                <div className="row mt-5">
                    <div className="col-3 d-md-block" >
                        <div className="fake-mackie-two" align="left">home</div>
                        <div><ul className="name-black">
                            <li onClick={(e) => this.handleClick(8, e)} >current & notable</li>
                        </ul></div>

                        <div className="fake-mackie-two mt-4" align="left">add</div>
                        <div><ul className="name-black">
                            <li onClick={(e) => this.handleClick(1, e)} >Album</li>
                            <li onClick={(e) => this.handleClick(2, e)} >Artist</li>
                            <li onClick={(e) => this.handleClick(3, e)} >Tour Dates</li>
                        </ul></div>
                        <div className="fake-mackie-two mt-4" align="left">edit</div>
                        <div><ul className="name-black">
                            <li onClick={(e) => this.handleClick(4, e)} >Album</li>
                            <li onClick={(e) => this.handleClick(5, e)} >Artist</li>
                            <li onClick={(e) => this.handleClick(6, e)} >Tour Dates</li>
                            <li onClick={(e) => this.handleClick(7, e)} >Related Artists</li>

                        </ul></div>

                        <LogoutButton />
                    </div>

                    <div className="col-12 col-md-9">
                        <div className="container">

                            {(this.state.link === 1) ? <AlbumUpdate isNew={true} /> : null}
                            {(this.state.link === 2) ? <ArtistUpdate isNew={true} /> : null}
                            {(this.state.link === 3) ? <TourAdd /> : null}
                            {(this.state.link === 4) ? <AlbumUpdate isNew={false} /> : null}
                            {(this.state.link === 5) ? <ArtistUpdate isNew={false} /> : null}
                            {(this.state.link === 6) ? <TourUpdate /> : null}
                            {(this.state.link === 7) ? <RelatedArtists /> : null}
                            {(this.state.link === 8) ? <CurrentAndNotable /> : null}






                        </div></div ></div> 

            </React.Fragment >)
    }
}

export default AdminLanding