import React from 'react';
import { Formik, Field } from 'formik';
import '../ipecac.css'
import moment from 'moment'



const nullChecker = (info) => { if (info) { return info } else { return "" } }



const AlbumEditForm = (props) => (
    <div align="left">
        {(props.isNew === true) ? <div className="fake-mackie-two">ADD ALBUM</div> 
        : <div className="fake-mackie-two">EDIT ALBUM</div>}
        <Formik
            initialValues={{
                artistId: props.artistId,
                title: nullChecker(props.title),
                cat: nullChecker(props.cat),
                releaseDate: moment(props.releaseDate).format("YYYY-MM-DD"),
                buyUrl: nullChecker(props.buyUrl),
                bcId: nullChecker(props.bcId)
            }}

            validate={values => {
                const errors = {};

                if (!values.title) {
                    errors.title = 'Required'
                }
                if (!values.cat) {
                    errors.cat = 'Required'
                }


                return errors;
            }}

            onSubmit={(values, { setSubmitting }) => {
                console.log(values)
                setTimeout(() => {
                    setSubmitting(false);
                }, 400);
                props.isNew ? props.onCreate(values) : props.onSubmit(values)


            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="black-nav-admin mt-3">Artist Name</div>

                        {(props.isNew === true) ?


                            <Field
                                as="select"
                                className="admin-input"
                                name="artistId"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.artistId}><option>select artist...</option>{props.artistDropdown}</Field>

                            : <div>{props.artist.artist}</div>




                        }




                        <div className="black-nav-admin mt-3">ALBUM TITLE</div>
                        <input
                            type="text"
                            className="admin-input"
                            name="title"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.title}
                        />

                        {errors.title ? <div style={{ color: "red" }}>{errors.title}</div> : null}


                        <div className="black-nav-admin mt-3">CATALOG NUMBER</div>
                        <b>IPC-</b><input
                            type="text"
                            className="admin-input-small"
                            name="cat"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cat}
                        />

                        {errors.cat ? <div style={{ color: "red" }}>{errors.cat}</div> : null}


                        <div className="black-nav-admin mt-3">RELEASE DATE</div>
                        <input
                            type="date"
                            name="releaseDate"
                            className="admin-input"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.releaseDate}
                        />

                        <div className="black-nav-admin mt-3">smartURL</div>
                        <input
                            type="text"
                            name="buyUrl"
                            className="admin-input"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.buyUrl}
                        />

                        <             div className="black-nav-admin mt-3">Bandcamp ID</div>
                        <input
                            type="text"
                            name="bcId"
                            className="admin-input-small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.bcId}
                        />
                        <div className="mt-3">
                            <button type="submit" disabled={isSubmitting} className="btns">
                                Submit
           </button></div>
                    </form>
                )}
        </Formik>
    </div>
);

export default AlbumEditForm