import React from 'react'
import ArtistEditForm from './ArtistEditForm'
import ArtistService from '../services/ArtistService'
import { withRouter } from "react-router-dom";




class ArtistUpdate extends React.Component {



    state = {
        artistDropdown: [],
        artistInfo: [],
        isEdit: false,
        isNew: ""
    }

    componentDidMount(props) {
        ArtistService.selectAllBasicDetails(this.getArtistsSuccess, this.getArtistsError)
    }



    getArtistsSuccess = (response) => {
        let artistData = response
        artistData.sort((itema, itemb) => {
            const nameA = itema.artist.toLowerCase().replace(/^the\s+/, '');
            const nameB = itemb.artist.toLowerCase().replace(/^the\s+/, '');
            return nameA.localeCompare(nameB)
        })

        const mapArtists = (artist) => { return <option value={artist.artistId} key={artist.artistId}>{artist.artist}</option> }
        const artistDropdown = artistData.map(mapArtists)
        this.setState({
            artistDropdown: artistDropdown,
        })
    }


    getArtistsError(err) {
        console.log('Get all artists failed: ', err)
    }

    handleChange = (e) => {
        let id = (e.target.value)
        ArtistService.selectById(id, this.getByIdSuccess, this.getByIdError)

    }

    getByIdSuccess = (response) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                artistDropdown: [],
                artistInfo: response,
                isEdit: true
            }
        }
        )
        console.log(response)
    }


    getByIdError(err) { console.log('Get all artists failed: ', err) }

    artistUpdate = (data) => {
        ArtistService.updateArtist(data, this.artistUpdateSuccess, this.artistUpdateError)
    }
    artistUpdateSuccess = (resp) => {
        console.log("ARTIST UPDATE SUCCESS! ", resp)
        this.props.history.push("/artists")

    }
    artistUpdateError = (err) => {
        console.log("THERE WAS AN ERROR IN YOUR UPDATE: ", err)
    }

    artistCreate = (data) => {
        ArtistService.createArtist(data, this.artistCreateSuccess, this.artistCreateError)
    }
    artistCreateSuccess = (resp) => {
        console.log("ARTIST Create SUCCESS! ", resp)
        this.props.history.push("/artists")
    }
    artistCreateError = (err) => {
        console.log("THERE WAS AN ERROR IN YOUR Create: ", err)
    }


    render() {
        let artistInfo = this.state.artistInfo

        return (

            <React.Fragment>

                <div>
                    {(this.state.isEdit === false) && (this.props.isNew === false)
                        ? <div align="left"><select id="artistToEdit" onChange={this.handleChange}><option>select artist...</option>{this.state.artistDropdown}</select></div>
                        : <ArtistEditForm
                            artist={artistInfo.artist}
                            artistId={artistInfo.artistId}
                            bandUrl={artistInfo.bandUrl}
                            bio={artistInfo.bio}
                            nowActive={artistInfo.nowActive}
                            related={artistInfo.related}
                            key={artistInfo.artistId}
                            slug={artistInfo.slug}
                            history={this.props.history}
                            isNew={this.props.isNew}
                            onSubmit={this.props.isNew ? this.artistCreate : this.artistUpdate}

                        />}

                </div>

            </React.Fragment>
        )
    }
}

export default withRouter(ArtistUpdate)