import React from 'react'
import AlbumEditForm from './AlbumEditForm'
import ReleasesService from '../services/ReleasesService'
import ArtistService from '../services/ArtistService'
import { withRouter } from "react-router-dom";


class AlbumUpdate extends React.Component {

    state = {
        albumDropdown: [],
        albumInfo: [],
        artistDropdown: [],
        isEdit: false,
        isNew: ""
    }

    componentDidMount(props) {
    if (this.props.isNew === false){
        ReleasesService.selectAllBasicDetails(this.getReleasesSuccess, this.getReleasesError)}
    else {
        ArtistService.selectAllBasicDetails(this.getArtistsSuccess, this.getArtistsError)
    }
    }



    getReleasesSuccess = (response) => {
        let albumData = response
        


        albumData.sort((itema, itemb) => {
            const nameA = itema.releaseDate
            const nameB = itemb.releaseDate
            return nameB.localeCompare(nameA)
        })


        const mapAlbums = (album) => { return <option value={album.releases_id} key={album.releases_id}>{album.artists.artist} - {album.title}</option> }
        const albumDropdown = albumData.map(mapAlbums)



        this.setState({
            albumDropdown: albumDropdown,
        })
    }


    getReleasesError(err) {
        console.log('Get all releases failed: ', err)
    }


    getArtistsSuccess = (response) => {
        let artistData = response
        artistData.sort((itema, itemb) => {
            const nameA = itema.artist.toLowerCase().replace(/^the\s+/, '');
            const nameB = itemb.artist.toLowerCase().replace(/^the\s+/, '');
            return nameA.localeCompare(nameB)
        })

        const mapArtists = (artist) => { return <option value={artist.artistId} key={artist.artistId}>{artist.artist}</option> }
        const artistDropdown = artistData.map(mapArtists)
        this.setState({
            artistDropdown: artistDropdown,
        })
    }

    getArtistsError(err) {
        console.log('Get all artists failed: ', err)
    }


    handleChange = (e) => {
        let id = (e.target.value)
        ReleasesService.selectById(id, this.getByIdSuccess, this.getByIdError)

    }

    getByIdSuccess = (response) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                albumDropdown: [],
                albumInfo: response,
                isEdit: true
            }
        }
        
        )
        console.log(response)

    }


    getByIdError(err) { console.log('Get all artists failed: ', err) }

    albumUpdate = (data) => {
        data.releases_id = this.state.albumInfo.releases_id
        ReleasesService.updateAlbum(data, this.albumUpdateSuccess, this.albumUpdateError)
    }
    albumUpdateSuccess = (resp) => {
        console.log("album UPDATE SUCCESS! ", resp)
        this.props.history.push("/releases")
    }
    albumUpdateError = (err) => {
        console.log("THERE WAS AN ERROR IN YOUR UPDATE: ", err)
    }

    albumCreate = (data) => {
        console.log("FRONT END CREATE", data)
        ReleasesService.create(data, this.albumCreateSuccess, this.albumCreateError)
    }
    albumCreateSuccess = (resp) => {
        console.log("album UPDATE SUCCESS! ", resp)
        this.props.history.push("/releases")
    }
    albumCreateError = (err) => {
        console.log("THERE WAS AN ERROR IN YOUR UPDATE: ", err)
    }

    
    render() {
        let albumInfo = this.state.albumInfo

        return (

            <React.Fragment>

                <div>
                    {(this.state.isEdit === false) && (this.props.isNew === false)
                        ? <div align="left"><select id="artistToEdit" onChange={this.handleChange}><option>select album...</option>{this.state.albumDropdown}</select></div>
                        : <AlbumEditForm
                            artistDropdown={this.state.artistDropdown}
                            artist={albumInfo.artists}
                            artistId={albumInfo.artistId}
                            title={albumInfo.title}
                            cat={albumInfo.cat}
                            releaseDate={albumInfo.releaseDate}
                            buyUrl={albumInfo.buyUrl}
                            bcId={albumInfo.bcId}
                            key={albumInfo.releases_id}
                            history={this.props.history}
                            isNew={this.props.isNew}
                            onSubmit={this.albumUpdate}
                            onCreate={this.albumCreate}
                        />}

                </div>

            </React.Fragment>
        )
    }
}

export default withRouter(AlbumUpdate)










