import React from "react"
import { Link } from "react-router-dom";
import "../ipecac.css"

function TourArtist(props) {
    let artistThumb = `http://assets.ipecac.com/images/artists/${props.artistId}t.jpg`
    return (
        <React.Fragment>
            <div className="card border-0 my-3" onClick={props.onClick}>
                <img src={artistThumb} alt="artist" width="180px" className="d-block mx-auto mx-sm-0 pointer" />
                <div className="d-block mx-auto mx-sm-0 name pointer">
                <Link 
                            key={props.artistId}
                            to={`/tours/`} 
                            >{props.artist}</Link>
                    
                    
                    
                    
                    </div></div>
        </React.Fragment >
    )
}
export default TourArtist