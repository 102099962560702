import React from 'react'
import "../ipecac.css"
import TourDates from "./TourDates"
import TourService from '../services/TourService'

class TourPage extends React.Component {

    state = {
        tour: []
    }

    componentDidMount() {
        TourService.selectAll(this.getTourDatesSuccess, this.getTourDatesError)
    }

    getTourDatesSuccess = (responseObj) => {
        let tourDates = responseObj.response.data
        
        var filteredArtists = tourDates.filter((v,i,a)=>a.findIndex(t=>(t.artists.artist === v.artists.artist))===i)
    

        
        filteredArtists.sort((itema, itemb) => {
            const nameA = itema.artists.artist.toLowerCase().replace(/^the\s+/, '');
            const nameB = itemb.artists.artist.toLowerCase().replace(/^the\s+/, '');
            return nameA.localeCompare(nameB)
        })
        
            for (let j = 0; j < filteredArtists.length; j++) {
                filteredArtists[j].dates = [];
              for (let i = 0; i < tourDates.length; i++) {
                if (tourDates[i].artistId === filteredArtists[j].artistId) {
                    filteredArtists[j].dates.push(tourDates[i]);

}}}


const mapArtists = (artist) => {return <div className="container my-4" key={artist.artistId}><div className="fake-mackie mb-2">{artist.artists.artist}</div><TourDates tourDates={artist.dates}/></div>
}

const tour = filteredArtists.map(mapArtists)

        
        this.setState({tour: tour})
    
    }
    getTourDatesError = (err) => console.log(err)



    render() {
        return (
            <React.Fragment>
            <div align="center" className="mx-5"> 
            <div className="mx-5 col-10">{this.state.tour}</div></div>
            </React.Fragment>
        )
    }
}

export default TourPage