import React from 'react'
import ArtistService from '../services/ArtistService'
import TourService from '../services/TourService'
import TourDateEditForm from './TourDateEditForm'
import { withRouter } from "react-router-dom"
import Papa from 'papaparse'


class TourAdd extends React.Component {

    state = {
        artistDropdown: [],
        artistName: [],
        artistId: [],
        isEdit: false,
        isNew: ""
    }

    componentDidMount() {
        ArtistService.selectAllBasicDetails(this.getArtistsSuccess, this.getArtistsError)
    }


    getArtistsSuccess = (response) => {
        let artistData = response
        artistData.sort((itema, itemb) => {
            const nameA = itema.artist.toLowerCase().replace(/^the\s+/, '');
            const nameB = itemb.artist.toLowerCase().replace(/^the\s+/, '');
            return nameA.localeCompare(nameB)
        })

        const mapArtists = (artist) => { return <option value={artist.artistId} key={artist.artistId}>{artist.artist}</option> }
        const artistDropdown = artistData.map(mapArtists)
        this.setState({
            artistDropdown: artistDropdown,
        })
    }


    getArtistsError(err) {
        console.log('Get all artists failed: ', err)
    }

    handleChange = (e) => {
        let id = Number(e.target.value)
        let dropdown = (this.state.artistDropdown)
        const artistToEdit = dropdown.find(artist => artist.props.value === id)

        this.setState((prevState) => {
            return {
                ...prevState,
                artistName: artistToEdit.props.children,
                artistId: id,
                isEdit: true
            }
        }
        )
    }

getCsv = () => {let newFile = document.getElementById('tour-csv').files[0]
        this.convertCsv(newFile, this.returnCsvData )}


convertCsv = (file, callback) => {
    Papa.parse(file, {
        header: true,
        complete: function(results) {
            callback(results.data)
            }})}
    
returnCsvData = (data) => {
    for(let i=0;i<data.length;i++){
        data[i].artistId = this.state.artistId
        this.addTourDate(data[i])
    } 
    console.log("Wooooooo", data)



}
           



        
           
    
  
    
    


    
    
    



    addTourDate = (data) => {
        TourService.addTourDate(data, this.addTourDateSuccess, this.addTourDateError)
    }
    addTourDateSuccess = (resp) => {
        console.log("Tour date add SUCCESS! ", resp)
        this.props.history.push("/tours")
    }
    addTourDateError = (err) => {
        console.log("THERE WAS AN ERROR IN YOUR Create: ", err)
    }


    render() {
        let newShow = {tourIndex: null,
                         artistId: this.state.artistId,
                         date: null,
                         location: null,
                         venue: null,
                         tix: null}

        return (
        

            <React.Fragment>

                <div>
                    {(this.state.isEdit === false)
                        ? <div align="left"><select id="artistToEdit" onChange={this.handleChange}><option>select artist...</option>{this.state.artistDropdown}</select></div>
                        : <div className="container my-4"> 
                        <div className="fake-mackie mb-5">{this.state.artistName}</div>
                        <div className="fake-mackie-two">ADD TOUR DATES</div>
                        <input type="file" id="tour-csv" onChange={this.getCsv}/>
                        <div className="album">Please upload .csv file</div>
                        <TourDateEditForm
                         show={newShow}
                         isNew={true} 
                         onSubmit={this.addTourDate}/>
                        </div>}

                </div>

            </React.Fragment>
        )
    }
}

export default withRouter(TourAdd)